import React from "react";

//
import { graphql } from "gatsby"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { Link } from "gatsby"

import { Layout } from "../components/layout";

//
const shortcodes = { Link } // Provide common components here

export default function IndexPage({ data: { mdx } }) {
  return (
    <>
      <Layout>
        {/* <h1>{mdx.frontmatter.title}</h1> */}
        <MDXProvider components={shortcodes}>
          <MDXRenderer frontmatter={mdx.frontmatter}>{mdx.body}</MDXRenderer>
        </MDXProvider>
      </Layout>
    </>
  );
}

export const pageQuery = graphql`
  query {
    mdx(slug: {eq: "home"}) {
      id
      frontmatter {
        title
      }
      body
    }
  }
  
`